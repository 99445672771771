// polyfill for ES standard features
import 'babel-polyfill';

// polyfill for <picture> elements
import 'picturefill';

// polyfill for IntersectionObserver
// from: https://github.com/w3c/IntersectionObserver
import 'intersection-observer';

// polyfill for window.matchMedia
// https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
// from: https://github.com/paulirish/matchMedia.js
import 'matchmedia-polyfill/matchMedia';
import 'matchmedia-polyfill/matchMedia.addListener';

// polyfill for: CustomEvent
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
// from: https://github.com/krambuhl/custom-event-polyfill
// needed for vanilla-lazyload
import 'custom-event-polyfill';

// polyfill for NodeList.forEach
// from: https://github.com/imagitama/nodelist-foreach-polyfill
import 'nodelist-foreach-polyfill';

// polyfill for __proto__
// NOTE: It may cause bad performance!! Needed for IE10
// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/proto
// needed for swiper.js
// see: https://github.com/nolimits4web/swiper/blob/32a143ef4668f1f8d553857494910503c12382e5/CHANGELOG.md#swiper-410---released-on-january-13-2018
// import 'proto-polyfill';
